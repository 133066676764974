import React from 'react';
import {background, white} from "../colors";
import Card from "../ui/Card";
import Headline from "../ui/Headline";
import Link from "../ui/Link";
import Text from "../ui/Text";
import Icon from "../ui/Icon";
import styled from "@emotion/styled";

function CardBackside({width, height}) {
    return (
        <Card color={background} size={{width, height}}>
            <Container size={{width: width - 30, height: height - 30}}>
                <Headline>Marco Giesen</Headline>
                <Text>Software-Engineer</Text>
                <Text><Link tab href="https://www.affilicon.net/">AffiliCon GmbH</Link></Text>

                <List>
                    <ListItem>
                        <Link tab href="https://twitter.com/marco_giesen"><Icon name="twitter"/></Link>
                    </ListItem>
                    <ListItem>
                        <Link tab href="https://github.com/MarcoGiesen"><Icon name="github"/></Link>
                    </ListItem>
                    <ListItem>
                        <Link tab href="https://www.xing.com/profile/Marco_Giesen7"><Icon name="xing"/></Link>
                    </ListItem>
                    <ListItem>
                        <Link tab href="https://www.linkedin.com/in/marco-giesen-92a38a113/"><Icon name="linkedin"/></Link>
                    </ListItem>
                </List>

            </Container>

        </Card>
    );
}

export default CardBackside;

const Container = styled.div(({size}) => (`
  position: absolute;
  width: ${size.width}px;
  height: ${size.height}px;
  color: ${white};
  padding: 15px;
  z-index: 2;
`));

const List = styled.ul`
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
    display: inline;
    padding: 5px;
    font-size: 20px;
`;
