import React, {Component} from 'react';
import FlipCard from "./ui/FlipCard";
import CardBackside from "./components/CardBackside";
import CardFrontside from "./components/CardFrontside";
import styled from "@emotion/styled";

const width = 400;
const height = Math.round(width / 1.75);

class App extends Component {
  render() {
    return (
      <Container>
        <FlipCard width={width} height={height} frontside={
          <CardFrontside width={width} height={height}/>
        } backside={
          <CardBackside width={width} height={height}/>
        }/>
      </Container>
    );
  }
}

export default App;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
