import React from 'react';
import {primary, white} from "../colors";
import Card from "../ui/Card";
import Headline from "../ui/Headline";
import Slogan from "../ui/Slogan";
import styled from "@emotion/styled";
import {keyframes} from "@emotion/core";

function CardFrontside({width, height}) {
  return (
    <Container>
      <Card color={primary} size={{width, height}}>
        <InnerContainer>
          <Headline color={white}>Marco Giesen</Headline>
          <Slogan color={white}>Loves React(-Native), Symfony & simplicity</Slogan>
        </InnerContainer>
      </Card>
    </Container>
  );
}

export default CardFrontside;

const shake = keyframes`
  0% { transform: translate(2px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const Container = styled.div`
  animation: ${shake} 1s;
  animation-delay: 5s;
`;

const InnerContainer = styled.div`
  padding-top: 75px;
  text-align: center;
  color: ${white};
`;
