import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const Headline = styled.h1`
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 5px;
`;

Headline.propTypes = {
  children: PropTypes.string
};

export default Headline;
