import styled from "@emotion/styled";

const Card = styled.div(({size, color}) => {
    return `
        height: ${size.height}px;
        width: ${size.width}px;
        background: ${color};
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
        transition: 0.3s;
        border-radius: 2;
        :hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
        }
    `;
});

export default Card;

