import React from 'react';
import styled from "@emotion/styled";

function FlipCard({frontside, backside, width, height}) {
    return (
        <Container size={{width, height}}>
            <InnerContainer>
                <FrontContainer size={{width, height}}>
                    {frontside}
                </FrontContainer>
                <BackContainer size={{width, height}}>
                    {backside}
                </BackContainer>
            </InnerContainer>
        </Container>
    );
}

export default FlipCard;

const Container = styled.div(({size}) => (`
  height: ${size.height}px;
  width: ${size.width}px;
  perspective: 1000px;
  :hover > div {
    transform: rotateY(180deg);
  }
`));

const InnerContainer = styled.div`
    transition: 0.8s;
    transform-style: preserve-3d;
    position: relative;
`;

const FrontContainer = styled.div(({size}) => (`
  height: ${size.height}px;
  width: ${size.width}px;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: rotateY(0deg);
`));

const BackContainer = styled.div(({size}) => (`
  height: ${size.height}px;
  width: ${size.width}px;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
`));
