import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const Slogan = styled.div`
  font-size: 16px;
`;

Slogan.propTypes = {
  children: PropTypes.string
};

export default Slogan;

