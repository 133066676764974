import React from 'react';
import {primary, primaryLighter} from "../colors";
import styled from "@emotion/styled";

const A = styled.a`
  color: ${primary};
  text-decoration: none;
  :hover {
    color: ${primaryLighter};
  }
`;

function Link({children, href, tab}) {
  const target = tab ? '_blank' : undefined;

  return <A href={href} target={target} rel="noreferrer">{children}</A>;
}

export default Link;
